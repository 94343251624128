import Service from '@/helpers/service'

export default {
    get(page, params) {
        return Service().get('admin/manage-sub-menu?page=' + page, {params});
    },
    getByMenu(menu_id) {
        return Service().get('admin/get-sub-menu-by-menu/'+menu_id);
    }, 
    show(id) {
        return Service().get('admin/manage-sub-menu/show/'+id);
    },    
    create(params) {
        return Service().post('admin/manage-sub-menu/create', params);
    },
    update(id, params) {
        return Service().post('admin/manage-sub-menu/update/'+id, params);
    },
    delete(id) {
        return Service().post('admin/manage-sub-menu/delete/'+id);
    }
}